@import url('https://fonts.cdnfonts.com/css/hvdtrial-brandon-grotesque');

@font-face {
  font-family: Freeland;
  src: url("../public/Freeland.otf") format("opentype");
}

@font-face {
  font-family: Bodoni;
  src: url("../public/Bodoni.otf") format("opentype");
}

@font-face {
  font-family: BrandonGrotesque-Regular;
  src: url("../public/BrandonGrotesque-Regular.otf") format("opentype");
}

@font-face {
  font-family: BrandonGrotesque-Bold;
  src: url("../public/BrandonGrotesque-Bold.otf") format("opentype");
}

body {
  font-family: 'HvDTrial Brandon Grotesque', 'BrandonGrotesque-Regular', 'Segoe UI', sans-serif !important;
  font-size: 1.1rem;
  margin: 0;
  font-family: 'HvDTrial Brandon Grotesque', 'BrandonGrotesque-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  margin: 0;
}

body > #root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #363839;
}

#root > main {
  flex: 1;
  overflow-y: auto !important;
}

header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

header,
main,
footer {
  padding-left: 250px;
}

nav.top-nav {
  height: 100px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  background-color: transparent;
  position: sticky;
  top: 0px;
  z-index: 100;
}

.sidenav {
  width: 250px !important;
}

.sidenav-overlay {
  position: relative !important;
  padding-left: 250px !important;
}

.sidenav {
  /* position: fixed; */
  z-index: 1000 !important; /* Adjust as necessary */
}

.sticky-container {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #fffefd;
  padding: 10px 0;
  margin-bottom: 10px;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.text-bold {
  font-weight: 700 !important;
}

.text-small {
  font-size: 0.95rem;
}

.travel-map {
  height: 400px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
}

/* tb-md-black */
.tb-md-black {
  background-color: #363839 !important;
}

.tb-md-black-text {
  color: #363839 !important;
}

/* tb-off-white */
.tb-off-white {
  background-color: #f2f0e7 !important;
}

.tb-off-white-text {
  color: #f2f0e7 !important;
}

/* tb-teal */
.tb-teal {
  background-color: #0e9bac !important;
}

.tb-teal.lighten-1 {
  background-color: #2eabba !important;
}

.tb-teal.lighten-2 {
  background-color: #56bfcc !important;
}

.tb-teal.lighten-3 {
  background-color: #7fd3dc !important;
}

.tb-teal.lighten-4 {
  background-color: #b2ebee !important;
}

.tb-teal.lighten-5 {
  background-color: #e9fcfd !important;
}

.tb-teal.darken-1 {
  background-color: #098c9c !important;
}

.tb-teal.darken-2 {
  background-color: #057e8c !important;
}

.tb-teal.darken-3 {
  background-color: #02707d !important;
}

.tb-teal.darken-4 {
  background-color: #00626e !important;
}

.tb-teal-text {
  color: #0e9bac !important;
}

.tb-teal-text.text-lighten-1 {
  color: #2eabba !important;
}

.tb-teal-text.text-lighten-2 {
  color: #56bfcc !important;
}

.tb-teal-text.text-lighten-3 {
  color: #7fd3dc !important;
}

.tb-teal-text.text-lighten-4 {
  color: #b2ebee !important;
}

.tb-teal-text.text-lighten-5 {
  color: #e9fcfd !important;
}

.tb-teal-text.text-darken-1 {
  color: #098c9c !important;
}

.tb-teal-text.text-darken-2 {
  color: #057e8c !important;
}

.tb-teal-text.text-darken-3 {
  color: #02707d !important;
}

.tb-teal-text.text-darken-4 {
  color: #00626e !important;
}

/* tb-grey */
.tb-grey {
  background-color: #8c8782 !important;
}

.tb-grey.lighten-1 {
  background-color: #a39e9a !important;
}

.tb-grey.lighten-2 {
  background-color: #bab5b1 !important;
}

.tb-grey.lighten-3 {
  background-color: #d1cdc9 !important;
}

.tb-grey.lighten-4 {
  background-color: #e8e5e1 !important;
}

.tb-grey.lighten-5 {
  background-color: #fdf7f3 !important;
}

.tb-grey.lighten-6 {
  background-color: #fffefd !important;
}

.tb-grey.darken-1 {
  background-color: #75716c !important;
}

.tb-grey.darken-2 {
  background-color: #5e5a56 !important;
}

.tb-grey.darken-3 {
  background-color: #47443e !important;
}

.tb-grey.darken-4 {
  background-color: #302e2b !important;
}

.tb-grey-text {
  color: #8c8782 !important;
}

.tb-grey-text.text-lighten-1 {
  color: #a39e9a !important;
}

.tb-grey-text.text-lighten-2 {
  color: #bab5b1 !important;
}

.tb-grey-text.text-lighten-3 {
  color: #d1cdc9 !important;
}

.tb-grey-text.text-lighten-4 {
  color: #e8e5e1 !important;
}

.tb-grey-text.text-lighten-5 {
  color: #fdf7f3 !important;
}

.tb-grey-text.text-darken-1 {
  color: #75716c !important;
}

.tb-grey-text.text-darken-2 {
  color: #5e5a56 !important;
}

.tb-grey-text.text-darken-3 {
  color: #47443e !important;
}

.tb-grey-text.text-darken-4 {
  color: #302e2b !important;
}

/* success-green */
.success-green {
  background-color: #3d9973 !important;
}

.success-green-text {
  color: #3d9973 !important;
}

/* success-green-light */
.success-green-light {
  background-color: #a5d7be !important;
}

.success-green-light-text {
  color: #a5d7be !important;
}

/* error-red */
.error-red {
  background-color: #d1685d !important;
}

.error-red-text {
  color: #e68473 !important;
}

/* error-red-light */
.error-red-light {
  background-color: #f5b2a7 !important;
}

.error-red-light-text {
  color: #f5b2a7 !important;
}

/* warning-yellow-light */
.warning-yellow {
  background-color: #fde5b3 !important;
}

.warning-yellow-text {
  color: #f3c666 !important;
}

/* warning-yellow-light */
.warning-yellow-light {
  background-color: #fde5b3 !important;
}

.warning-yellow-light-text {
  color: #fde5b3 !important;
}

/* .mobile-friendly-table {
  display: none;
}

@media only screen and (max-width : 992px) {

  header,
  main,
  footer {
    padding-left: 0;
  }
}

@media only screen and (min-width: 601px) {

  nav,
  nav .nav-wrapper i,
  nav a.sidenav-trigger,
  nav a.sidenav-trigger i {
    height: 64px;
    line-height: 64px;
  }
}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 0;
  }
  .accommodation-logs-table thead, 
  .accommodation-logs-table tbody tr {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .mobile-friendly-table {
    display: block;
  }
  
  .accommodation-logs-table thead, 
  .accommodation-logs-table tbody tr {
    display: none;
  }
} */

.mobile-friendly-table {
  display: none; /* Hide by default */
}

@media only screen and (max-width: 1000px) {
  /* Show the mobile-friendly layout and hide the regular table layout at or below certain width */
  .mobile-friendly-table {
    display: block; /* Or flex, grid, etc., depending on layout needs */
    /* Add more styling here */
  }

  .accommodation-logs-table thead, .accommodation-logs-table tbody tr {
    display: none;
  }
}

.date-input-week-selector {
  text-align: center;
  font-weight: bold;
  color: #363839;
}

input[type=text]:focus {
  border-bottom: 1px solid #0e9bac !important;
  box-shadow: 0 1px 0 0 #0e9bac !important;
}

input[type=text]:focus + label {
  color: #0e9bac !important;
}

.input-field .prefix.active {
  color: #0e9bac !important;
}

[type="checkbox"]+span {
  padding-top: 0px !important;
}

.tb-checkbox-label [type="checkbox"]+span:not(.lever):before {
  /* border: 2px solid #8c8782 !important;
  background-color: white !important; */
  border: 2px solid #8c8782;
  border-radius: 2px;
}

.tb-checkbox-label [type="checkbox"]+span:not(.lever) {
  padding-left: 20px;
}

.modal.download-report-modal .modal-content .report-checkbox-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Ensures items start from the left */
  padding-left: 140px;
}

.modal.download-report-modal .modal-content .report-checkbox-column {
  flex: 1 1 50%; /* Each column takes up half of the container width */
  min-width: 250px; /* Prevents columns from being too narrow */
  text-align: left; /* Ensures text within each column is left-aligned */
}

.modal.download-report-modal .modal-content .report-checkbox-column p {
  margin: 0; /* Removes default paragraph margin */
  text-align: left; /* Ensures text within the paragraph is left-aligned */
}

.report-checkbox-column input[type="checkbox"].filled-in:disabled+span:not(.lever) {
  cursor: not-allowed !important; /* or 'default' based on your preference */
}

/* .report-checkbox-column label {
  cursor: inherit;
} */

.report-checkbox-column input[type="checkbox"].filled-in:disabled,
.report-checkbox-column label.disabled-label {
  cursor: default !important; /* Using !important to override any other cursor styles */
}

.report-checkbox-column input[type="checkbox"].filled-in:disabled:checked+span:not(.lever):after {
  background-color: #bab5b1 !important; /* Using !important to override any other cursor styles */
}


.report-divider {
  width: 90%;
  border: 2px solid #363839;
  border-radius: 2px;
  margin-top: 80px;
  margin-bottom: 50px;
}

.input-field > .material-symbols-outlined {
  font-size: 1.9rem !important;
}

.search-input::placeholder {
  color: #8c8782;
  font-size: 1.2rem;
}

.search-input {
  padding-left: 10px !important;
  color: #363839 !important;
  font-size: 1.2rem !important;
  font-weight: 500;
}

.date-input::placeholder {
  color: #8c8782;
  font-size: 1.2rem;
}

.date-input {
  color: #363839;
  font-size: 1.2rem;
  font-weight: 500;
}

.modal.add-log-modal .select__input-container {
  margin: 0px 2px;
  padding: 0px 2px;
}

/* [type="checkbox"]+span:not(.lever):before {
  border: 2px solid #8c8782 !important;
  border-radius: 5px !important;
  background-color: white;
} */

[type="checkbox"]:checked+span:not(.lever):before {
  border-left: none !important;
  border-top: none !important;
  border-right: 3px solid #0e9bac !important;
  border-bottom: 3px solid #0e9bac !important;
  background-color: transparent !important;
}

.prop-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.prop-chip-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.chip.prop-chip {
  display: inline-flex;
  align-items: center;
  margin: 0px;
  gap: 4px;
}

.switch.property-switch label .lever {
  margin: 0 10px;
  width: 52px; 
  height: 22px;
  background-color: #e8e5e1;
}

.switch.property-switch label input[type=checkbox]:checked+.lever:after {
  left: 24px;
  background-color: #2eabba;
  font-family: 'Material Icons';
  color: #37474f;
  font-size: 1.3rem;
}

.switch.property-switch label input[type=checkbox]:checked+.lever {
  background-color: #d1cdc9;
}

.switch.property-switch label .lever:before {
  height: 28px;
  width: 28px;
}

.switch.property-switch label .lever:after {
  background-color: #f2f0e7;
  font-family: 'Material Icons';
  color: #37474f;
  font-size: 1.3rem;
  height: 28px;
  width: 28px;
}

#toast-container {
  bottom: auto !important;
  left: auto !important;
  top: 15% !important;
  right: 6% !important;

}

.hide-tooltip {
  pointer-events: none; /* Disables mouse events on the element */
  cursor: default; /* Ensures the cursor does not change to indicate a tooltip */
}

/* If tooltips are managed via a visibility or display property */
.hide-tooltip .tooltip-content {
  display: none; /* Hides the tooltip content if that's how your tooltips are structured */
}

.toast {
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

.scoreboard h5 {
  font-family: 'Freeland', 'Bodoni', sans-serif;
  font-weight: 'bold';
  font-size: 2.2rem;
}

.scoreboard p {
  font-family: 'Bodoni', sans-serif;
  font-weight: 'bold';
  font-size: 1.2rem;
}

.trickling-dots-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #ccc;
  border-radius: 50%;
  animation: tricklingDots 1.4s infinite both;
}

.dot1 { animation-delay: -0.32s; }
.dot2 { animation-delay: -0.16s; }
.dot3 { animation-delay: 0; }

@keyframes tricklingDots {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
}

@media only screen and (max-width: 992px) {
  header, main, footer {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) {
  nav.top-nav {
    height: 80px;
  }
}

@media only screen and (min-width: 601px) {
  nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
    height: 64px;
    line-height: 64px;
  }
}

.dropdown-content li>span {
  color: #057e8c !important;
}

[type="radio"]:checked+span:after {
  background-color: #057e8c !important;
  border: 2px solid #057e8c !important;
}

.report-radio-columns label {
  color: #47443e !important;
}


input {
  font-family: 'HvDTrial Brandon Grotesque', 'BrandonGrotesque-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.apexcharts-text tspan {
  font-family: 'HvDTrial Brandon Grotesque', 'BrandonGrotesque-Regular', 'Noto Sans', sans-serif !important;
  font-size: 0.9rem;
  color: #47443e;
}

.apexcharts-legend-text {
  font-family: 'HvDTrial Brandon Grotesque', 'BrandonGrotesque-Regular', 'Noto Sans', sans-serif !important;
}

.property-details-table td:nth-child(1) {
  width: 50%;
}

.property-details-table td:nth-child(2) {
  width: 50%;
}



/* @media screen and (min-width: 993px) {
  nav.top-nav {
    height: 80px;
  }
} */

.nav-wrapper .row {
  margin-bottom: 0px !important;
}

a.sidenav-trigger.top-nav {
  position: absolute;
  text-align: center;
  height: 48px;
  width: 48px;
  top: 28px;
  float: none;
  margin-left: 1.5rem;
  color: #f3989b;
  font-size: 36px;
  z-index: 2;
}

#front-page-logo {
  display: inline-block;
  height: 100%;
  pointer-events: none;
}

#logo-container {
  height: 57px;
  margin-bottom: 32px;
  height: 100%;
}

.report-title {
  font-family: 'Bodoni', sans-serif;
  color: #47443e;
}

ul.sidenav.sidenav-fixed li.logo:hover,
ul.sidenav.sidenav-fixed li.logo #logo-container:hover {
  background-color: transparent;
}

ul.sidenav.sidenav-fixed li.logo {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 36px;
}

.modal.add-log-modal {
  border-radius: 10px;
  width: 70%;
  min-height: 80%;
}

.select__control {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important; */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important; */
}

.accommodation-logs .select__input-container {
  margin: 0px 2px;
  padding: 0px 2px;
}

.bed-night-reports .select__input-container {
  margin: 0px 2px;
  padding: 0px 2px;
}

.properties .select__input-container {
  margin: 0px 2px;
  padding: 0px 2px;
}

.modal.add-log-modal label {
  color: #47443e;
  font-size: 0.9rem;
}

.modal.add-log-modal .select__input-container {
  margin: 0px 2px;
  padding: 0px 2px;
}

.select__single-value {
  color: #098c9c !important;
  font-weight: 700;
  font-size: 1.1rem;
}

.select__multi-value {
  /* color: #098c9c !important; */
  font-weight: 700;
  background-color: #e8e5e1 !important;
}

.select__multi-value__label {
  color: #098c9c !important;
  font-weight: 700;
}

.select__control--is-focused  {
  border-color: #098c9c !important;
  box-shadow: 0 0 0 1px #098c9c !important;
}

.select__control--is-focused:hover  {
  border-color: #098c9c !important;
  box-shadow: 0 0 0 1px #098c9c !important;
}

.select--has-value .select__control {
  border-color: #098c9c !important;
  border-width: 2px !important;
}

.select-sm-placeholder .select__placeholder {
  font-size: 1rem !important;
}

.input-dark {
  margin-right: 10px;
  flex-grow: 1;
}

/* Style for darker placeholder text */
.input-placeholder-dark::placeholder {
  color: #5e5a56;
}

textarea {
  font-family: 'HvDTrial Brandon Grotesque', 'BrandonGrotesque-Regular', 'Segoe UI', sans-serif !important;
}

textarea:focus {
  border-bottom: 1px solid #0e9bac !important;
  box-shadow: 0 1px 0 0 #0e9bac !important;
}

.date-input-modal {
  color: #5e5a56;
}

.date-input-modal::placeholder {
  color: #5e5a56;
}

.modal.add-edit-modal {
  border-radius: 10px;
  width: 50%;
  min-height: 70%;
}

.modal.add-edit-modal .modal-content {
  border-radius: 10px;
  overflow-y: auto;
}

.modal.referral-modal {
  border-radius: 10px;
  width: 60%;
  min-height: 80%;
}

.modal.referral-modal .modal-content {
  border-radius: 10px;
  overflow-y: auto;
}


.modal.download-report-modal {
  border-radius: 10px;
  width: 90%;
  min-height: 80%;
}

.modal.download-report-modal .modal-content {
  border-radius: 10px;
  overflow-y: auto;
}

.modal.property-detail-modal {
  border-radius: 10px;
  width: 60%;
  min-height: 85%;
}

.modal.property-detail-modal .modal-content {
  border-radius: 10px;
  overflow-y: auto;
}

/* .modal {
  border-radius: 10px;
  width: 70%;
  min-height: 80%;
} */

input.file-path[type=text]:not(.browser-default) {
  border-bottom: 1px solid #8c8782 !important;
}

input.file-path.valid[type=text]:not(.browser-default) {
  border-bottom: 1px solid #0e9bac !important;
  box-shadow: 0 1px 0 0 #0e9bac !important;
}

input.file-path::placeholder {
  color: #8c8782;
}

.currency-select-wrapper .select-dropdown {
  z-index: 3000 !important;
  top: 100% !important;
  transform: none !important;
}

.copyable-text {
  transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
}

.copyable-text .material-symbols-outlined{
  transition: color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
}

.copyable-text:hover {
  color: #098c9c;
  font-weight: 700 !important;
}

.copyable-text:hover .material-symbols-outlined {
  color: #098c9c !important;
}

.material-tooltip.tooltip-light {
  background-color: rgba(0, 98, 110, 0.8) !important;
  border-radius: 4px !important;
}

.material-tooltip.tooltip-updated-by {
  background-color: rgba(0, 98, 110, 0.8) !important;
  border-radius: 4px !important;
  min-width: 200px;
  max-width: 400px;
}

ul.custom-icons {
  list-style-type: none; /* Removes default bullet points */
  padding-left: 10px; /* Adds indentation to the list itself */
}

ul.custom-icons li {
  position: relative;
  padding-left: 44px; /* Adjusted space for the icon to ensure text doesn't overlap with the icon */
}

ul.custom-icons li:before {
  content: 'chevron_right'; /* Use 'favorite' icon or any other Material Icon */
  position: absolute;
  left: 20px; /* Aligns the icon with the text start, adjusted for better positioning */
  top: 0; /* Adjust as needed to align vertically with your list item text */
  /* transform: translateX(200%); Moves the icon to the left of its starting point */
  font-family: 'Material Icons';
  color: #bdb6b0; /* Custom color for the icon */
  font-size: 20px; /* Adjust size as needed, considering the overall alignment and appearance */
}


.react-datepicker__close-icon {
  background-color: transparent !important; /* Change to your desired color */
  color: white; /* Adjust text/icon color as needed */
  /* You might need additional styles here depending on what you're trying to achieve */
}

.react-datepicker__close-icon::after {
  background-color: #f5b2a7 !important; /* Change to your desired color */
  color: white; /* Adjust text/icon color as needed */
  /* You might need additional styles here depending on what you're trying to achieve */
}

.react-datepicker__close-icon:active::after {
  transform: scale(1.1); /* Scales the icon up */
  outline: 2px solid #e68473; /* Adds an outline, adjust color as needed */
  outline-offset: 0px; /* Adjust the space between the icon and the outline */
}

.modal.add-log-modal .modal-content {
  border-radius: 10px;
  /* Apply the border-radius here as well */
  overflow-y: auto;
  /* This will make sure the scrollbar appears inside the modal-content */
}

table.accommodation-logs-table {
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  table-layout: fixed;
}

table.sample-report-table {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

table.sample-report-table thead {
  height: 10px;
}

table.sample-report-table th {
  font-size: 1.3rem;
  text-align: center;
  font-weight: 600;
  padding: 5px;
  border-radius: 3px 3px 0px 0px;
  /* border-top: 1px solid #8c8782;
  border-right: 1px solid #8c8782;
  border-left: 1px solid #8c8782; */
}

table.sample-report-table tr {
  background-color: #fffefd;
}

table.sample-report-table td {
  /* border: 1px solid #8c8782; */
  text-align: center;
}

table.sample-report-table tr:last-child td {
  /* font-size: 1rem; */
  text-align: center;
  font-weight: 400;
  padding: 5px;
  border-radius: 0px 0px 3px 3px;
}

table.overlaps-table {
  /* border-collapse: separate; */
  border-spacing: 0;
  width: 100%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  border-radius: 8px;
  overflow-y: auto;
}

table.overlaps-table tr:last-child {
  border-bottom: none !important;
}

table.overlaps-table th, td {
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0px;
}

table.overlaps-table td>div {
  width: 100%;
  border-radius: 6px;
  /* box-shadow: rgba(186, 181, 177, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  box-shadow: rgba(186, 181, 177, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.overlaps-table thead th {
  letter-spacing: 0.08em;
  position: sticky;
  top: 105px;
  z-index: 6;
}

textarea.materialize-textarea.trip-report-comments {
  background-color: white; /* Set the background color to white */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 5px; /* Rounded corners */
  outline: none;
  height: 100px; /* Make it taller */
  width: 100%;
  font-size: 16px;
  margin: 0 0 16px 0; /* Add some margin at the bottom */
  padding: 10px; /* Add padding inside the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  box-sizing: border-box; /* Ensure padding and border are included in the height/width */
  transition: box-shadow .3s, border .3s;
  resize: vertical; /* Allow vertical resizing */
}

.suggestions-list {
  position: absolute;
  top: 100%; /* Aligns the top of the suggestions list with the bottom of the input field */
  left: 0; /* Aligns the list to the left of the container */
  margin-top: -1px; /* Adjust this value to close the gap */
  padding: 5px 0; /* Adds padding on top and bottom, removes left/right padding */
  z-index: 1000;
  width: 100%; /* Ensure it matches the input field width */
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  border: 1px solid #ddd;
  border-top: none;
}

.suggestions-list li {
  padding: 8px 10px; /* Adds padding to the left and right of list items */
  cursor: pointer; /* Changes the mouse cursor to a pointer to indicate clickable items */
}

.suggestions-list li:hover {
  background-color: #e9fcfd; /* Changes the background color on hover */
  font-weight: bold; /* Makes the text bold on hover */
}

.pagination li button {
  color: #444;
  display: inline-block;
  font-size: 1.1rem;
  padding: 0 10px;
  line-height: 30px;
}

table.accommodation-logs-table {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

table.accommodation-logs-table td p {
  margin-block-start: 0;
  margin-block-end: 0;
}

table.accommodation-logs-table td .chip {
  margin-bottom: 5px;
}

table.accommodation-logs-table td {
  padding: 10px;
}

table.accommodation-logs-table th {
  padding: 10px;
}

.accommodation-logs-table>th,
td {
  /* border: 1px solid #ddd; */
  padding: 8px;
  text-align: left;
}

.accommodation-logs-table th {
  background-color: #0e9bac;
}

.accommodation-logs-table tr {
  transition: background-color 0.3s ease-in-out; /* Add this line */
}

.accommodation-logs-table tr:nth-child(even) {
  background-color: #e8e5e1;
}

.accommodation-logs-table tr:nth-child(odd) {
  background-color: #fdf7f3;
}

table.accommodation-logs-table tr:last-child {
  border-bottom: none !important;
}

.accommodation-logs-table tr:hover {
  background-color: #d1cdc9;
}

table.accommodation-logs-table td {
  vertical-align: top;
}

table.accommodation-logs-table td div {
  vertical-align: top;
}

table.accommodation-logs-table>thead>tr>th:first-child {
  border-top-left-radius: 10px;
}

table.accommodation-logs-table>thead>tr>th:last-child {
  border-top-right-radius: 10px;
}

table.accommodation-logs-table>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 10px;
}

table.accommodation-logs-table>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 10px;
}

table.accommodation-logs-table>thead>tr>th {
  border-radius: 0px;
}

table.accommodation-logs-table>tbody>tr>td {
  border-radius: 0px;
}


table.rates-table th, table.rates-table td {
  padding: 3px 10px; /* Half the padding of the accommodation-logs-table for smaller row heights */
}

table.rates-table>thead>tr>th:first-child {
  border-top-left-radius: 6px;
}

table.rates-table>thead>tr>th:last-child {
  border-top-right-radius: 6px;
}
table.rates-table>tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 6px;
}

table.rates-table>tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 6px;
}

.date-selectors {
  display: flex;
  gap: 20px;
  /* Adjust or keep the gap as needed */
  align-items: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  /* Adjusted to take the full width of its container */
  justify-content: space-between;
  /* This will space out the children elements */
}

.spinner-blue, .spinner-blue-only {
  border-color: #0e9bac !important;
}

.spinner-red, .spinner-red-only {
  border-color: #8c8782 !important;
}

.spinner-yellow, .spinner-yellow-only {
  border-color: #057e8c !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__day--selected {
  background-color: #0e9bac !important; /* Your desired color for selected date */
  color: white !important; /* Optional: change text color for better readability */
}

/* Change the background color of the date on hover */
.react-datepicker__day--keyboard-selected,
.react-datepicker__day:hover {
  background-color: #e8e5e1 !important; /* Your desired color for hovered date */
  color: black !important; /* Optional: change text color for better readability */
}

.react-datepicker__navigation--previous:active,
.react-datepicker__navigation--next:active {
  background-color: #8c8782 !important; /* Your desired color for the button background when clicked */
}

.react-datepicker__navigation--previous:focus,
.react-datepicker__navigation--next:focus {
  background-color: #8c8782 !important; /* Your desired color for the button background when clicked */
}

.vertically-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.chip.large-chip {
  font-size: 1.5rem;
  height: auto;
  padding: 10px 20px;
  border-radius: 30px;
}

th {
  white-space: nowrap;
  min-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.new-existing-prop:hover {
  background-color: rgba(0, 0, 0, 0.05);
  /* Light background on hover */
  cursor: pointer;
  /* Change cursor to indicate clickability */
  /* text-decoration: underline; */
  /* Underline text to indicate it's clickable */
}

.card.new-property-card {
  border-radius: 8px;
  transition: box-shadow 0.25s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.card.wrapped-card {
  border-radius: 2px;
  min-height: 400px;
  margin-top: 20px;
  background-color: #d1cdc9;
  transition: box-shadow 0.25s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.card.potential-trip-card {
  border-radius: 8px;
  margin-bottom: 30px;
  transition: box-shadow 0.25s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.card.referral-tree-card {
  border-radius: 10px 10px 10px 10px;
  /* background-color: pink; */
}

.card.referral-tree-card .card-action:last-child {
  border-radius: 0px 0px 10px 10px;
}

.card.referral-tree-card .card-content {
  padding: 12px 10px 0px 10px;
}

.card.referral-details-card {
  border-radius: 0px 0px 10px 10px;
  padding-top: 0px;
  margin-top: 0px;
  transition: box-shadow 0.25s;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card.referral-match-card {
  border-radius: 10px;
  transition: box-shadow 0.25s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card.referral-match-card.audited-referral {
  background-color: #a5d7be;
  border: 2px solid #3d9973;
  border-radius: 10px;
  transition: box-shadow 0.25s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.potential-trips .btn.chip:hover {
  background-color: #057e8c;
}

.material-symbols-outlined {
  font-size: 18px;
  vertical-align: middle !important;
  margin-bottom: 4px;
  margin-right: 2px;
}

.material-symbols-rounded {
  vertical-align: middle !important;
  margin-bottom: 2px;
}

.right-align {
  text-align: right;
}